import {Network} from "alchemy-sdk";

export const coinFlipAddress = process.env.REACT_APP_COINFLIP_ADDRESS;
export const TOURNAMENT_FACTORY_ADDRESS = process.env.REACT_APP_TOURNAMENT_ADDRESS;

export const controlWalletAddress = "0x8c18175D448D41Edaade9786525Eaef8CB499e1e";
export const TreasuryWalletAddress = "0x26EFDfe20047f0F071aE949Fef61Cc2307F3a2eb";

export const tournamentOwnerAddress = "0x78a5fF0cFe7dAf83D4E44D22491c279843A6D782";
export const tournamentRole = "0x5d0cede6381d3dfcaff5d15157cc588341b6cdbcc282b013dfcc2d54ff20c721";
export const NFTAddress = "0x50d2915927255109570d9f72ebeb8cb477df508e";
export const networkSettings = {
  apiKey: process.env.REACT_APP_ALCHEMY_KEY, // Replace with your Alchemy API Key.
  network: (process.env.REACT_APP_NETWORK !== "MATIC") ? Network.MATIC_MUMBAI : Network.MATIC_MAINNET,
};
