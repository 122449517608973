import React, { useEffect, useState } from "react";
import useMetaMask from "../../hooks/useMetaMask";
import coinFlipAbi from "../../coinflip.json";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Alchemy } from "alchemy-sdk";
import {coinFlipAddress, controlWalletAddress, networkSettings, NFTAddress, TreasuryWalletAddress} from "../../consts";
const { ethers } = require("ethers");

const alchemy = new Alchemy(networkSettings);

function Coinflip() {
  const [guess, setGuess] = useState(null);
  const [betAmount, setBetAmount] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [loseAlert, setLoseAlert] = useState("");
  const [winAlert, setWinAlert] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [amount, setAmount] = useState(0);
  const { account } = useMetaMask();
  const [raffleTitle, setRaffleTitle] = useState("");

  const withdraw = async() => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const coinFlipContract = new ethers.Contract(coinFlipAddress, coinFlipAbi, signer);
    try {
      await coinFlipContract.withdraw( ethers.utils.parseEther((amount).toString()));
    } catch (error) {
      console.log(error);
    }
  };

  const updateTeamWallet = async(e)=> {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const coinFlipContract = new ethers.Contract(coinFlipAddress, coinFlipAbi, signer);
    try {
      await coinFlipContract.setTeam(e.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const updateTreasuryWallet = async(e)=> {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const coinFlipContract = new ethers.Contract(coinFlipAddress, coinFlipAbi, signer);
    try {
      await coinFlipContract.setTreasury(e.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const airdrop = async() => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const coinFlipContract = new ethers.Contract(coinFlipAddress, coinFlipAbi, signer);
    
    try {
      const owners = await alchemy.nft.getOwnersForContract(NFTAddress);

      let nftData = new Object({});
      let rewardDataAddresses = [];
      let rewardDataValues = [];

      let total_gold = 0;
      let total_ice = 0;
      let total_obsidian = 0;

      for (let i = 0; i < owners.owners.length;i++) {

        let temp_gold = 0;
        let temp_obsidian = 0;
        let temp_ice = 0;

        for await (const nft of alchemy.nft.getNftsForOwnerIterator(owners.owners[i])) {
          if(nft["contract"]["address"].toLowerCase() === NFTAddress.toLowerCase()) {   
            const nftType = nft["rawMetadata"]["attributes"][0]["value"];
            if(nftType === "Gold") {
              temp_gold++;
              total_gold++;
            } else if (nftType ==="Ice") {
              temp_ice++;
              total_ice++;
            } else if (nftType === "Obsidian") {
              temp_obsidian++;
              total_obsidian++;
            } 
          }
        }
        nftData[owners.owners[i]] = [temp_gold,temp_ice,temp_obsidian];
      }
      
      for (let i = 0; i<owners.owners.length;i++) {
        rewardDataAddresses.push(owners.owners[i]);
        rewardDataValues.push(parseInt((nftData[owners.owners[i]][0] / total_gold  + nftData[owners.owners[i]][1] / total_ice  + nftData[owners.owners[i]][2] /total_obsidian) * 33000));
      }

      provider.getBalance(account).then(async(balance) => {
        const balanceInMatic = ethers.utils.formatEther(balance);
        await coinFlipContract.distributeTokens(rewardDataAddresses,rewardDataValues,{ value: ethers.utils.parseEther((balanceInMatic*0.99).toString()) });
      });
    } catch (error) {
      console.log(error);
    }
  };

  const takeCoinFlip = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const coinFlipContract = new ethers.Contract(coinFlipAddress, coinFlipAbi, signer);
    const network = await provider.getNetwork();
    const networkName = network.name;
    if (
      (networkName === "maticmum" && networkSettings["network"] !== "polygon-mumbai") ||
        (networkName === "matic" && networkSettings["network"] !== "polygon-mainnet") ||
        (networkSettings["network"] !== "polygon-mainnet" && networkSettings["network"] !== "polygon-mumbai")
    ) {
      setErrorMessage(`Please set ${networkSettings["network"]} in Metamask`);
      return;
    }

    provider.getBalance(account).then((balance) => {
      // convert a currency unit from wei to ether
      const balanceInMatic = ethers.utils.formatEther(balance);
      if (balanceInMatic < betAmount) {
        setErrorMessage("You don't have enough Matic for this flip");
        return;
      }
    });

    setWinAlert("");
    setLoseAlert("");
    setErrorMessage("");

    if (guess === null) {
      setErrorMessage("Please pick one of HEADS or TAILS");
      return;
    }

    if (betAmount <= 0) {
      setErrorMessage("Please select the correct amount");
      return;
    }

    try {
      setIsLoading(true);
      let betAmountWithFee = betAmount * 1.04;
      const tx = await coinFlipContract.flip({ value: ethers.utils.parseEther((betAmountWithFee).toString()) });
      coinFlipContract.on("Win", (to, amount, event) => {
        console.log(to, amount, event);
        if (to === account) {
          setIsLoading(false);
          setWinAlert(`Congratulations! You won ${Number(amount)/(2*Math.pow(10,18))} matic!`);
        }
      });

      coinFlipContract.on("Lose", (to, amount, event) => {
        console.log(to, amount, event);
        if (to===account) {
          setIsLoading(false);
          setLoseAlert(`Rugged! You Lost ${Number(amount)/Math.pow(10,18)} matic!`);
        }
      });

      const result = await tx.wait();
      if (result !== null) {
        console.log("success");
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      if(error["code"] === "ACTION_REJECTED") setErrorMessage("User denied transaction signature");
      else setErrorMessage("Failed, Please try again");
    }
    setBetAmount(0);
    setGuess(null);
  };

  useEffect(() => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);

    provider.getBalance(coinFlipAddress).then((balance) => {
      // convert a currency unit from wei to ether
      const balanceInMatic = ethers.utils.formatEther(balance);
      setAmount(balanceInMatic);
    });
  },[]);
  useEffect(() => {
      let end = null;
      const _second = 1000;
      const _minute = _second * 60;
      const _hour = _minute * 60;
      const _day = _hour * 24;
      let timer = null;

      const showRemaining =()=> {
            
          const now = new Date();
          const nowUTC = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds());

          const distance = end - nowUTC;


          if (distance < 0) {

            clearInterval(timer);
            document.getElementById('countdown').innerHTML = 'EXPIRED!';

            return;
          }
          const days = Math.floor(distance / _day);
          const hours = Math.floor((distance % _day) / _hour);
          const minutes = Math.floor((distance % _hour) / _minute);
          const seconds = Math.floor((distance % _minute) / _second);
          if(document.getElementById('countdown'))
            document.getElementById('countdown').innerHTML = "Time Remaining: " + days + "d " + hours + "h "
          + minutes + "m " + seconds + "s ";
      }

      const loadGoogleSheetData = async () => {
        try {
          await new Promise((resolve, reject) => {
            window.gapi.load('client', { callback: resolve, onerror: reject });
          });

          await window.gapi.client.init({
            apiKey: process.env.REACT_APP_GAPI_KEY, // Replace with your API key
            discoveryDocs: ['https://sheets.googleapis.com/$discovery/rest?version=v4'],
          });

          const response = await window.gapi.client.sheets.spreadsheets.values.get({
            spreadsheetId: process.env.REACT_APP_YOUR_SPREADSHEET_ID, // Replace with your spreadsheet ID
            range: 'display!A1:G', // Replace with your desired sheet name and range
          });

          const loadedData = response.result.values;

          let flag = false;

          for (let i = 1; i < loadedData.length; i++) {
            if(loadedData[i][1].toLowerCase().slice(0,4)==account.toLowerCase().slice(0,4) && loadedData[i][1].toLowerCase().slice(-4)==account.toLowerCase().slice(-4) ){
              document.getElementById('entry').innerHTML = "You have " + loadedData[i][3] + "&nbsp;Entries";
              flag=true;
            } 
            if(flag) break;
          }
          
          if(!flag) {
            document.getElementById('entry').innerHTML = "You have no Entries";
          }
          
          const rulesLines =  loadedData[1][6];
          let ruleText = "";
          for(let i = 0; i<rulesLines.length;i++) {
            if(rulesLines[i]=="\n")
              ruleText+='<br></br>'
            else 
              ruleText+=rulesLines[i];
          }
          document.getElementById('rule').innerHTML =ruleText;

          setRaffleTitle(loadedData[0][6]);

          const timeremaining = loadedData[2][6];

          //var end = new Date('5/8/2023 15:55');
          end = new Date(timeremaining);
          timer = setInterval(showRemaining, 1000);
        } catch (error) {
          console.log(error);
        }
  };

      // Load the Google API client
      if(account){
        loadGoogleSheetData();
      }
  }, [account]);


  return (
    <div>
      {account!==controlWalletAddress && account!==TreasuryWalletAddress &&
      <div className=" w-full sm:w-[100%] 2xl:w-full m-auto mt-[20px]">

        <div className='flex justify-center text-[20px] font-[700] pt-[1vh] uppercase'>CHOOSE</div>

        <div className='flex justify-center gap-x-4 pt-[3vh]'>
          <div className="flex flex-row text-[14px]  font-[700] space-x-4 text-[#000000]">
            <div className={`${guess === 1 ? "scale-110" : ""} flex flex-col hover:scale-110`}><button onClick={() => setGuess(1)}>{guess === 1 ?<img src="img/head-hover.svg" />:<img src="img/head.svg" />}HEAD</button></div>
            <div className={`${guess === 0 ? "scale-110" : ""} flex flex-col hover:scale-110`}><button onClick={() => setGuess(0)}>{guess === 0 ?<img src="img/tail-hover.svg" />:<img src="img/tail.svg" />}TAIL</button></div>
          </div>
        </div>
        
        <div className='flex justify-center  pt-[3vh] uppercase  text-[20px] font-[700] py-3'>FOR</div>
        <div>
          <div className="flex justify-center font-[700] pt-[1vh] gap-x-4">
            <div>
              <button className={`${betAmount === 1 ? "bg-[#004D87] text-[#ffffff] " : "bg-[#D6EFFF] hover:bg-[#004D87] text-[#000000] hover:text-[#ffffff]"} text-[17px] px-[20px] py-[10px] text-center  hover:scale-110   rounded-[7px]`} onClick={() => setBetAmount(1)} width={"140px"} height={"50px"}>
                1 MATIC
              </button>
            </div>
            <div>
              <button className={`${betAmount === 2 ? "bg-[#004D87] text-[#ffffff] " : "bg-[#D6EFFF] hover:bg-[#004D87] text-[#000000] hover:text-[#ffffff]"} text-[17px] px-[20px] py-[10px] text-center  hover:scale-110   rounded-[7px]`} onClick={() => setBetAmount(2)} width={"140px"} height={"50px"}>
                2 MATIC
              </button>
            </div>
            <div>
              <button className={`${betAmount === 5 ? "bg-[#004D87] text-[#ffffff] " : "bg-[#D6EFFF] hover:bg-[#004D87] text-[#000000] hover:text-[#ffffff]"} text-[17px] px-[20px] py-[10px] text-center  hover:scale-110   rounded-[7px]`} onClick={() => setBetAmount(5)} width={"140px"} height={"50px"}>
                5 MATIC
              </button>
            </div>
          </div>
          <div className=' flex justify-center font-[700] pt-[1vh] gap-x-4'>
            <div>
              <button className={`${betAmount === 10 ? "bg-[#004D87] text-[#ffffff] " : "bg-[#D6EFFF] hover:bg-[#004D87] text-[#000000] hover:text-[#ffffff]"} text-[17px] px-[20px] py-[10px] text-center  hover:scale-110   rounded-[7px]`} onClick={() => setBetAmount(10)} width={"140px"} height={"50px"}>
                10 MATIC
              </button>
            </div>
            <div>
              <button className={`${betAmount === 25 ? "bg-[#004D87] text-[#ffffff] " : "bg-[#D6EFFF] hover:bg-[#004D87] text-[#000000] hover:text-[#ffffff]"} text-[17px] px-[20px] py-[10px] text-center  hover:scale-110   rounded-[7px]`} onClick={() => setBetAmount(25)} width={"140px"} height={"50px"}>
                25 MATIC
              </button>
            </div>
          </div>
          <div className={`${errorMessage ? "mt-4" : ""} flex justify-center font-bold text-[24px] text-[#ff0066] uppercase`}>
            {errorMessage}
          </div>
          {winAlert !== "" && <div className='flex justify-center font-[800] text-[20px] mt-[35px] text-[#133F03] uppercase'>
            {winAlert}
          </div>}
          {loseAlert !== "" && <div className='flex justify-center font-[800] text-[20px] mt-[35px] text-[#C81111] uppercase'>
            {loseAlert}
          </div>}
          {isLoading ?
            <div className="flex items-center justify-center">
              <div className="flex flex-row justify-center w-[322px] h-[50px] px-[8px] sm:px-[10px] py-[3px] sm:py-[5px] my-[3vh] text-center bg-[#D6EFFF] text-[#000000]  text-[24px]  rounded-[5px] uppercase font-[700]">
                {/* <svg className="w-5 h-5 animate-spin my-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="#054462" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="#054462"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                  </path>
                </svg> */}
                 <img src="img/flipping.gif" className="rounded-[50%]"/>
                <p className="my-auto">Flipping...</p>
              </div>
             
            </div>
            :
            <div className='flex justify-center'>
              <button className=' w-[322px] h-[50px] px-[8px] sm:px-[10px] py-[3px] sm:py-[5px] my-[3vh] text-center bg-[#004D87] hover:bg-[#D6EFFF] hover:scale-110  text-[#ffffff] hover:text-[#000000] text-[24px] rounded-[5px] uppercase font-[700]' onClick={() => takeCoinFlip()}>
                Click here to flip
              </button>
            </div>}

        </div>
      </div>
      }
      {
        account===TreasuryWalletAddress &&<div><p></p>
          <button className=' w-[322px] h-[50px] px-[8px] sm:px-[10px] py-[3px] sm:py-[5px] my-[3vh] text-center bg-white hover:scale-110  active:bg-cyan-300 text-xl sm:text-2xl' onClick={() => airdrop()}>
          Airdrop
          </button></div>
      }
      {account===controlWalletAddress && <div className=''>
        <p></p>
        <input value={amount} onChange={(e) => setAmount(e.target.value)}/><p></p>
        <button className=' w-[322px] h-[50px] px-[8px] sm:px-[10px] py-[3px] sm:py-[5px] my-[3vh] text-center bg-white hover:scale-110  active:bg-cyan-300 text-xl sm:text-2xl' onClick={() => withdraw()}>
          Withdraw
        </button>
        <p></p>
        <input placeholder="Please input team wallet address" onChange={(e) => updateTeamWallet(e.target.value)}/><p></p>
        <button className=' w-[322px] h-[50px] px-[8px] sm:px-[10px] py-[3px] sm:py-[5px] my-[3vh] text-center bg-white hover:scale-110  active:bg-cyan-300 text-xl sm:text-2xl' onClick={() => withdraw()}>
          Team
        </button>
        <p></p>
        <p></p>
        <input  placeholder="Please input treasury wallet address" onChange={(e) => updateTreasuryWallet(e.target.value)}/><p></p>
        <button className=' w-[322px] h-[50px] px-[8px] sm:px-[10px] py-[3px] sm:py-[5px] my-[3vh] text-center bg-white hover:scale-110  active:bg-cyan-300 text-xl sm:text-2xl' onClick={() => withdraw()}>
          Treasury
        </button>
        <p></p>
      </div>
      }
      <div className="text-[#000000] font-bold"><Link to="/plays" className="visited:text-[#000000]">View Recent Plays</Link></div>
      <div className="bg-indigo-600 bg-opacity-70 text-white text-center py-6 mx-auto w-2/3 sm:w-1/2 lg:w-1/3   mt-10 rounded-lg" id="gsheet">
        <h2 className="text-[1.5rem] uppercase mb-4 drop-shadow-[2px_2px_0_rgb(86,86,86)]">Active Giveaway</h2>
        <div id="countdown" className="mb-4"></div>
        <div className="my-4" id="entry"></div>
        <p className="text-lg font-bold">{raffleTitle}</p>
        <div className="mx-10 sm:mx-20 lg:mx-30 text-left">
          <p className="text-lg uppercase" id="rule"></p>
        </div>
        <Link to="/leadboard" className="bg-purple-600 text-white py-2 px-4 rounded-lg inline-block mt-4 font-bold uppercase">View Leaderboard</Link>
      </div>
      <div className="mt-10 mb-20 font-bold"><Link to="/faq" className=" text-sm sm:text-base text-[#000000] no-underline">FAQ</Link> | <Link to="/howto" className=" text-sm sm:text-base text-[#000000] no-underline">How To Play</Link> | <Link to="/responsibility" className=" text-sm sm:text-base text-[#000000] no-underline">Flip Responsibly</Link></div>
      <div className="font-bold">
        <div>Audited By</div>
        <div className="flex justify-center mb-[50px]" width={"250px"} height={"50px"}><img src="img/audit.png"/></div>
      </div>
      <div className="relative hidden lg:flex">
        <div className="absolute bottom-0 right-0">
          <img src="img/bigcow-right.png"/>
        </div>
      </div>
    </div>
  );
}

export default Coinflip;
