import React from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <div className="relative flex mt-[30px] ml-[80px]">
      <div className="flex gap-x-4 mt-[10vh] md:mt-[0vh]">
        <div className="hover:scale-110 px-2"><a href="https://discord.gg/Qvaa36nHtT" target="_blank" rel="noreferrer"><img src="img/discord.png" width={"50px"} height={"50px"}/></a></div>
        <div className="hover:scale-110 px-2"><a href="https://twitter.com/IcedOutCoinFlip" target="_blank" rel="noreferrer"><img src="img/twitter.png" width={"50px"} height={"50px"}/></a></div>
        <div className="hover:scale-110 px-2"><a  href="https://polygonscan.com/address/0xac637b9b71affdbd350a6a629fcc9313c48738a4" target="_blank" rel="noreferrer"><img src="img/polygon.png" width={"177px"} height={"50px"}/></a></div>
      </div>
      <div className="flex gap-x-4 text-center absolute top-0 right-5 uppercase">
        <div width={"140px"} height={"50px"}><Link to="/" className="font-[700]  rounded-[5px] px-[15px] py-[10px]  my-auto no-underline text-[#000000] hover:text-[#ffffff]  bg-[#D6EFFF] hover:bg-[#004D87]">Coin Flip</Link></div>
        <div width={"140px"} height={"50px"}><Link to="/tournament" className="font-[700]  rounded-[5px] px-[15px] py-[10px]  my-auto no-underline text-[#000000] hover:text-[#ffffff]  bg-[#D6EFFF] hover:bg-[#004D87]">Big Pot</Link></div>
      </div>
    </div>
  );
};

export default Navbar;
