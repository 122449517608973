import API from "./api";

const login = async (signData) => {
  // eslint-disable-next-line no-undef
  try {
    const res = await API.post("login", signData);
    sessionStorage.setItem("token", res.data.token);
    return true;
  } catch(error) {
    console.log(error);
    return false;
  }
};

const depositMatics = async(depositData) => {
  try{
    const headers = { "authorization": sessionStorage.getItem("token") };
    const res = await  API.post("/", depositData,{headers});
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

const takeCoinFlip = async(flipData) => {
  try{
    const headers = { "authorization": sessionStorage.getItem("token") };
    const res = await API.put("updateTournamnetById", flipData,{headers});
    return res.data;
  } catch(error) {
    console.log(error);
  }
};

const getTournamentBalance = async(tournament) => {
  try{
    const res = await API.get(`getAllById/${tournament}`);
    return res.data;
  } catch(error){
    console.log(error);
  }
};


const getWinnersBalance = async(tournament) => {
  try{
    const res = await API.get(`getWinners/${tournament}`);
    return res.data;
  } catch(error){
    console.log(error);
  }
};

const getUserTournament = async(tournament,address) => {
  try{
    const res = await API.get(`findByIdOne/${tournament}/${address}`);
    return res.data;
  } catch(error){
    console.log(error);
  }
};

export const tournamentService = {
  login,
  depositMatics,
  takeCoinFlip,
  getTournamentBalance,
  getWinnersBalance,
  getUserTournament,
};
