import React, { useEffect,useState } from 'react';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fontsource/silkscreen';
import { Link } from "react-router-dom";
const LeaderBoard = () => {
    const [raffleTitle, setRaffleTitle] = useState("");

    useEffect(() => {
        let end = null;
        const _second = 1000;
        const _minute = _second * 60;
        const _hour = _minute * 60;
        const _day = _hour * 24;
        let timer = null;

        const showRemaining =()=> {
              
            const now = new Date();
            const nowUTC = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds());

            const distance = end - nowUTC;


            if (distance < 0) {

              clearInterval(timer);
              document.getElementById('countdown').innerHTML = 'EXPIRED!';

              return;
            }
            const days = Math.floor(distance / _day);
            const hours = Math.floor((distance % _day) / _hour);
            const minutes = Math.floor((distance % _hour) / _minute);
            const seconds = Math.floor((distance % _minute) / _second);

            if(document.getElementById('countdown'))
                document.getElementById('countdown').innerHTML = "Time Remaining: " + days + "d " + hours + "h "
            + minutes + "m " + seconds + "s ";
        }

        const loadGoogleSheetData = async () => {
          try {
            await new Promise((resolve, reject) => {
              window.gapi.load('client', { callback: resolve, onerror: reject });
            });

            await window.gapi.client.init({
              apiKey: process.env.REACT_APP_GAPI_KEY, // Replace with your API key
              discoveryDocs: ['https://sheets.googleapis.com/$discovery/rest?version=v4'],
            });

            const response = await window.gapi.client.sheets.spreadsheets.values.get({
              spreadsheetId: process.env.REACT_APP_YOUR_SPREADSHEET_ID, // Replace with your spreadsheet ID
              range: 'display!A1:G', // Replace with your desired sheet name and range
            });

            const loadedData = response.result.values;

            
            const rulesLines =  loadedData[1][6];
            let ruleText = "";
            for(let i = 0; i<rulesLines.length;i++) {
              if(rulesLines[i]=="\n")
                ruleText+='<br></br>'
              else 
                ruleText+=rulesLines[i];
            }
            document.getElementById('raffleRule').innerHTML =ruleText;

            setRaffleTitle(loadedData[0][6]);

            const timeremaining = loadedData[2][6];


            // populate HTML table with data
            const table = document.getElementsByTagName('table')[0];
            
            // add column headers
            const columnHeaders = document.createElement('tr');
            columnHeaders.innerHTML = 
              `<th>${loadedData[0][0]}</th>
              <th>${loadedData[0][1]}</th>
              <th>${loadedData[0][2]}</th>
              <th>${loadedData[0][3]}</th>
              <th>${loadedData[0][4]}</th>
              `;

            table.appendChild(columnHeaders);

            // add table data rows
            for (let i = 1; i < loadedData.length; i++) {
              const tableRow = document.createElement('tr');
              tableRow.innerHTML = 
                `<td>${loadedData[i][0]}</td>
                <td>${loadedData[i][1]}</td>
                <td>${loadedData[i][2]}</td>
                <td>${loadedData[i][3]}</td>
                <td>${loadedData[i][4]}</td>
                `;
              table.appendChild(tableRow);
            }

            //var end = new Date('5/8/2023 15:55');
            end = new Date(timeremaining);
            timer = setInterval(showRemaining, 1000);
          } catch (error) {
            console.log(error);
          }
    };

        // Load the Google API client
        loadGoogleSheetData();
  }, []);

  return (
    <div>
        <div className="relative flex pt-[20px] bg-[#343a40] pb-[30px]">
          <div className="flex gap-x-4 mt-[10vh] md:mt-[0vh]">
            <div className="hover:scale-110 px-2"><a href="https://discord.gg/Qvaa36nHtT" target="_blank" rel="noreferrer"><img src="img/discord.png" width={"50px"} height={"50px"}/></a></div>
            <div className="hover:scale-110 px-2"><a href="https://twitter.com/IcedOutCoinFlip" target="_blank" rel="noreferrer"><img src="img/twitter.png" width={"50px"} height={"50px"}/></a></div>
            <div className="hover:scale-110 px-2"><a  href="https://polygonscan.com/address/0xac637b9b71affdbd350a6a629fcc9313c48738a4" target="_blank" rel="noreferrer"><img src="img/polygon.png" width={"177px"} height={"50px"}/></a></div>
          </div>
          <div className="flex gap-x-4 text-center absolute top-0 right-5 uppercase pt-[20px]">
            <div width={"140px"} height={"50px"}><Link to="/" className="font-[700]  rounded-[5px] px-[15px] py-[10px]  my-auto no-underline text-[#000000] hover:text-[#ffffff]  bg-[#D6EFFF] hover:bg-[#004D87]">Coin Flip</Link></div>
            <div width={"140px"} height={"50px"}><Link to="/tournament" className="font-[700]  rounded-[5px] px-[15px] py-[10px]  my-auto no-underline text-[#000000] hover:text-[#ffffff]  bg-[#D6EFFF] hover:bg-[#004D87]">Big Pot</Link></div>
          </div>
        </div>
        <div className="bg-[#343a40]">
            <div className="container bg-[#343a40]">
                <div className="row">
                    <div className="col-2">
                    </div>
                    <div className="col">
                        <h3 className="display-4 text-center text-warning">{raffleTitle}</h3>
                        <p  className="text-primary text-center" id="raffleRule"></p>
                        <p className="text-primary text-center" id="countdown"></p>
                    </div>
                    <div className="col-2">
                    </div>
                </div>
                <div className="row">
                    <div className="col-2">
                    </div>
                    <div className="col">
                        <div className="table-responsive">
                            <table className="table table-hover table-dark">
                            </table>
                        </div>
                    </div>
                    <div className="col-2">
                    </div>
                </div>
            </div>
        </div>
    </div>

  );
};

export default LeaderBoard;
