import React from 'react';
import DisplayPlays from "../components/coinflip/recentplays";
import "./App.css";
import useMetaMask from "../hooks/useMetaMask";
// import { Link } from "react-router-dom";

import "@fontsource/silkscreen";

function Plays() {
  const {
    connect,
    isActive,
    shouldDisable
  } = useMetaMask();

  return (
    <div className=' h-[100vh] bg-[url("../public/img/bg.jpg")] bg-bottom bg-cover bg-repeat-x overflow-x-hidden'>
      <div className='flex flex-col'>
      <div className=' py-[1vh] mx-auto mt-5'>
          <div className="font-[700] text-[66px] text-center uppercase ">Iced Out Coin Flip</div>
      </div>
          {
            !isActive ?
              <button className='mt-10 px-[20px] py-[10px] font-bold text-white outline-[2px] outline-dashed outline-cyan-400 bg-cyan-400 hover:bg-white hover:text-cyan-500 hover:scale-110 hover:outline-offset-4 duration-1000 hover:animate-pulse' onClick={connect} disabled={shouldDisable}>
                Connect to MetaMask
              </button>
              :
              <DisplayPlays />
          }
        </div>
      </div>
  );
}

export default Plays;
