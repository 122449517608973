/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fontsource/silkscreen";
import { Link } from "react-router-dom";
import TOURNAMENT_FACTORY_ABI from "../tournament.json";
import { TOURNAMENT_FACTORY_ADDRESS,tournamentRole,tournamentOwnerAddress } from "../consts.js";
import useMetaMask from "../hooks/useMetaMask";
import { tournamentService } from "../services/tournament";
import { SignMessage } from "../utils/SignMessage";
import Countdown from "react-countdown";
import Navbar from "../components/Navbar/navbar";

import "react-datepicker/dist/react-datepicker.css";
const { ethers } = require("ethers");


const signMessage = async ({ setError, message }) => {
  try {
    if (!window.ethereum)
      throw new Error("No crypto wallet found. Please install it.");

    await window.ethereum.send("eth_requestAccounts");
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const signature = await signer.signMessage(message);
    const address = await signer.getAddress();

    return {
      message,
      signature,
      address
    };
  } catch (err) {
    setError(err.message);
  }
};

const Tournament = () => {
  const [sign, setSign] = useState(false);
  const [isStated, setStart] = useState(0);
  const [betAmount, setBetAmount] = useState(0);
  const [depositAmount, setDepositAmount] = useState(0);
  const [guess, setGuess] = useState(null);
  const [error, setError] = useState();
  const [signatures, setSignatures] = useState([]);
  const [startTime, setStartTime] = useState(0);
  const [endTime, setEndTime] = useState(0);
  const [currentTournamnetID, setCurrentTournamentID] = useState(0);
  const [currentCreatedTournament, setCurrentCreatedTournament] = useState(null);
  const [init, setInit] = useState(false);

  const [tournamentAddressForRole, setTournamentAddress] = useState("");
  const [isTASetting,setIsTASetting] = useState("");

  const [isCreating, setIsCreating] = useState(false);
  const [isSetting, setIsSetting] = useState(false);

  const [isWinning, setIsWinning] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isDepositing, setDepositing] = useState(false);
  const [isDeposited, setIsDeposit] = useState(false);
  const [depositErrorMsg, setDepositErrorMsg] = useState("");

  const [status, setTournamentStatus] = useState("");
  const [timeStatus, setTournamentTimeStatus] = useState("");

  const [isWinner, setWinning] = useState(0);

  const [countdownTime, setCountDownTime] = useState(0);
  const [enterTime, setEnterTime] = useState(0);

  const [creatorRole, setTournamnetCreator] = useState(false);


  const [tournamentsResults, setTournamentResult] = useState([]);
  const [totalBalance, setTotalBalance] = useState(0);
  const [myBalance, setMyBalance] = useState(0);
  const [totalPlayers, setTotalPlayers] = useState(0);
  const [remainPlayers, setRemainPlayers] = useState(0);
  const [count, setCount] = useState(0);
  const [flipError, setFlipError] = useState("");
  const [choice, setChoice] = useState(0);

  const [claimed, setClaimed] = useState(false); // check if winners have been setted
  const [isClaiming, setIsClaiming] = useState(false); // check if the user is claiming the reward now
  const [isPrize, setIsPrize] = useState(false); // check if the user has been already claimed the reward

  const percentage =[50,20,10,5,5];

  const { isActive,account } = useMetaMask();
  let requestSent = false;

  const handleSign = async (option) => {
    setError();
    const sig = await signMessage({
      setError,
      message: option!=="Deposit"?`I am betting ${betAmount} ICE on ${guess===1?"HEAD":"TAIL"}`:`I have deposited ${depositAmount} MATIC`
    });
    if (sig) {
      setSignatures([...signatures, sig]);
      return(sig);
    }
  };
  //done
  const CreateTournament = async()=> {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const tournamentContract = new ethers.Contract(
      TOURNAMENT_FACTORY_ADDRESS,
      TOURNAMENT_FACTORY_ABI,
      signer
    );

    if(startTime <= 0 || endTime <=0){
      setTournamentTimeStatus("Please input the correct Time!");
      return;
    }

    if(endTime < startTime) {
      setTournamentTimeStatus("EndTime should be greater than Start Time!");
      return;
    }

    const now = Math.floor(new Date().getTime()/1000);
    setIsCreating(true);
    try {
      const tx = await tournamentContract.createTournament(now+startTime*3600, now+endTime*3600);

      tournamentContract.on("CreateTournament", (_tournamentID, _startTime, _endTime,_totalDepositAmount,players,event) => {
        setTournamentStatus(`${Number(_tournamentID)} tournamnet has just been created`);
      });

      const result = await tx.wait();
      if (result !== null) {
        console.log("New tournamet has just been created");
        // getLiveTournament();
      }
    } catch (error) {
      console.log(error);
      setTournamentTimeStatus("Failed, Please try again");
      setIsCreating(false);
    }
    setIsCreating(false);
  };

  //processing
  const getTournamentBalance= async(tournamentID) => {
    if(tournamentID>0){
      const _tournamentsResults = await tournamentService.getTournamentBalance(tournamentID);
      setTournamentResult(_tournamentsResults);
      setRemainPlayers(_tournamentsResults.length);
      return _tournamentsResults;
    }
  }

  const getWinnersBalance= async(tournamentID) => {
    if(tournamentID>0){
      const _tournamentsResults = await tournamentService.getWinnersBalance(tournamentID);
      setTournamentResult(_tournamentsResults);
      // setRemainPlayers(_tournamentsResults.length);
      return _tournamentsResults;
    }
  }

  const getUserTournament = async(tournamentID,address) => {
    if(tournamentID>0 ){
      const _tournamentsUserResult = await tournamentService.getUserTournament(tournamentID, address);
      if(myBalance>_tournamentsUserResult[0]["balance"]) {
        setChoice(1);
      } else   if(myBalance!=0 && myBalance<_tournamentsUserResult[0]["balance"]) {
        setChoice(2);
      }
      setMyBalance(_tournamentsUserResult[0]["balance"]);
      setCount(_tournamentsUserResult[0]["count"])
    }
  }
  
  // processing
  const getLiveTournament = async() => {
    if(currentCreatedTournament===null) return;

    const startime = currentCreatedTournament["tournamentStartTime"] - new Date().getTime()/1000;
    const endtime =  currentCreatedTournament["tournamentEndTime"] - new Date().getTime()/1000;

    if(currentCreatedTournament["existed"]) {
      setTotalPlayers(Number(currentCreatedTournament["players"]));

      if(startime>0){
        setEnterTime(Math.floor(startime));
        setTournamentStatus(`${Number(currentCreatedTournament["tournamentID"])} tournament is in progress`);
        setStart(0);
      }else if(startime<0 && endtime>0) {
        setStart(1);
        setEnterTime(Math.floor(endtime));
        getTournamentBalance(currentTournamnetID);
        getUserTournament(currentTournamnetID, account);
      } else if(startime < 0 && endtime<0) {
        setEnterTime(0);
        getWinnersBalance(currentTournamnetID);
        setStart(2);
        setWinning(2);
        setCountDownTime(Date.now() + endtime * 1000 + 700000);
      }
    }
    else {
      setEnterTime(0);
      if(currentTournamnetID===0)setStart(0);
      else setStart(2);
      getWinnersBalance(currentTournamnetID);

      if(currentCreatedTournament["winnerSetting"]) {
        setWinning(1);
        setClaimed(true);
        setCountDownTime(Date.now() + endtime * 1000 + 700000);
        if(currentCreatedTournament["claimedToTeam"]){
          setTournamentStatus("Please wait for next tournament")
        }
      } else {
        setWinning(0);
      }
    }
  }
  // set deposit amount
  const setDepositAmountForTournament = async() => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const tournamentContract = new ethers.Contract(TOURNAMENT_FACTORY_ADDRESS, TOURNAMENT_FACTORY_ABI, signer);
    setIsSetting(true);
    try{
      const tx = await tournamentContract.updateDepositAmount(ethers.utils.parseEther((depositAmount).toString()));
      tournamentContract.on("DeleteTournament", async(_tournamentID,_user,event) => {
        console.log(_tournamentID,_user,event);
      });
    } catch (error) {
      console.log(error);
      setIsSetting(false);
    }
    setIsSetting(false);

  }

    // set deposit amount
    const setTournamnetAddressForRole = async() => {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const tournamentContract = new ethers.Contract(TOURNAMENT_FACTORY_ADDRESS, TOURNAMENT_FACTORY_ABI, signer);
      setIsTASetting(true);
      try{
        const tx = await tournamentContract.setTournamentRole(tournamentAddressForRole);
      } catch (error) {
        console.log(error);
        setIsTASetting(false);
      }
      setIsTASetting(false);
  
    }

  //done
  const depositMatic = async(event) => {
    event.preventDefault();
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const tournamentContract = new ethers.Contract(TOURNAMENT_FACTORY_ADDRESS, TOURNAMENT_FACTORY_ABI, signer);

    if(currentTournamnetID===0) return;
    setDepositing(true);
    setDepositErrorMsg("");

    provider.getBalance(account).then((balance) => {
      // convert a currency unit from wei to ether
      const balanceInMatic = ethers.utils.formatEther(balance);
      if (balanceInMatic < betAmount) {
        setDepositErrorMsg("You don't have enough Matic for this flip");
        setDepositing(false);
        return;
      }
    });

    try{
      const tx = await tournamentContract.deposit({ value: ethers.utils.parseEther((depositAmount).toString())});
      tournamentContract.on("Deposit", async(_tournamentID,_user,_amount, event) => {
        // update the pool size
        const createdTournaments = await tournamentContract.createdTournaments(Number(_tournamentID));
        setTotalBalance(Number(createdTournaments["totalDepositAmount"])/Math.pow(10,18));
        if (requestSent) {
          // Request has already been sent, do not send another request
          return;
        }
        try{
          setDepositing(false);
          const sign = await handleSign("Deposit");
          await tournamentService.depositMatics({
              "signature": sign.signature,
              "message": sign.message,
              "tournament":Number(_tournamentID)
          });
          setIsDeposit(true);
          requestSent = true;
        } catch(error) {
          console.log(error);
        }
      });

      const result = await tx.wait();
      if (result !== null) {
        console.log("Deposit Success");

      }
    } catch(error){
      setDepositing(false);
      setDepositErrorMsg("Deposit Error. You have already deposited MATIC (entered) or there was a connection error! Please try again!");
      console.log(error);
    }
  }
  
  // done
  const takeCoinFlip = async() => {
    setChoice(0);
    setIsLoading(true);
    setFlipError("");

    if(myBalance<betAmount){
      setFlipError("YOU'RE OUT OF ICE!");
      setIsLoading(false);
      return;
    }
    if(count < 1) {
      setFlipError("YOU'RE OUT OF Flips!");
      setIsLoading(false);
      return;
    }
    if (guess === null) {
      setFlipError("Please pick one of HEADS or TAILS");
      setIsLoading(false);
      return;
    }

    if (betAmount === 0) {
      setFlipError("Miss the ICE balance");
      setIsLoading(false);
      return;
    }

    if(account!=sessionStorage.getItem("account")){
      setFlipError("You didn't sign to play the game! Please sign the message");
      setIsLoading(false);
      if(handleMessage())
        sessionStorage.setItem("account",account);
      return;
    }

    
    try{
      const sign =await handleSign("coin flip");
      if(sign){
        const tx = await tournamentService.takeCoinFlip({
          "signature": sign.signature,
          "message": sign.message,
          "flip": guess,
          "amount":betAmount,
          "tournament": currentTournamnetID
        });
        getUserTournament(currentTournamnetID, account);
      }
    } catch(error) {
      console.log(error);
      setFlipError("Time Out Error!!!");
    }
    setIsLoading(false);
  }
    
  //processing
  const SetTournamentWinner = async() => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const tournamentContract = new ethers.Contract(TOURNAMENT_FACTORY_ADDRESS, TOURNAMENT_FACTORY_ABI, signer);

    setIsWinning(true);
    try{
      const currentTournamnetID = await tournamentContract.currentTournamnetID() - 1;
      const _tournamentsResults = await tournamentService.getWinnersBalance(currentTournamnetID);
      const winner1 = _tournamentsResults[0]["address"];
      const winner2 = _tournamentsResults[1]["address"];
      const winner3 = _tournamentsResults[2]["address"];
      const winner4 = _tournamentsResults[3]["address"];
      const winner5 = _tournamentsResults[4]["address"];

      const tx = await tournamentContract.setTournamentWinner([winner1, winner2, winner3,winner4,winner5],currentTournamnetID);

      tournamentContract.on("SetWinnerOfTournament", (_tournamentID, _winner1, _winner2,_winner3,_winner4,_winner5,event) => {
        setIsWinning(false);
        setTournamentStatus(`${_tournamentID} tournament's winners have just been setted!`);
      });

      const result = await tx.wait();
      if (result !== null) {
        console.log("All winners are setted!");
      }
    } catch (error) {
      console.log(error);
      setIsWinning(false);

    }
    setIsWinning(false);

  };
  //processing
  const resetTournament = async() => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const tournamentContract = new ethers.Contract(TOURNAMENT_FACTORY_ADDRESS, TOURNAMENT_FACTORY_ABI, signer);
    
    try{
      const currentTournamnetID = await tournamentContract.currentTournamnetID() - 1;
      const tx = await tournamentContract.resetTournamament(currentTournamnetID);

      const result = await tx.wait();
      if (result !== null) {
        console.log("reset success");
      }
    } catch (error) {
      console.log(error);
      setIsWinning(false);
    }
  };

  const claim = () => {
    return(
      tournamentsResults.map((item, index) => {
        if(index<5){
         return(
            <tr key={index}>
              <td className="px-6 py-2 text-sm bg-[#004D87] rounded-[7px] whitespace-nowrap">{index+1}</td>
              <td className="px-6 py-2 text-sm bg-[#004D87] rounded-[7px] whitespace-nowrap">
                {item.address.slice(0,6)}...{item.address.slice(-4)}
              </td>
              <td className="px-6 py-2 text-sm bg-[#004D87] rounded-[7px] whitespace-nowrap">
                {totalBalance * percentage[index] /100 }(MATIC)
              </td>
            </tr>
          )
        }
      })
    )
  }

  const claimRewards = async()=> {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const tournamentContract = new ethers.Contract(TOURNAMENT_FACTORY_ADDRESS, TOURNAMENT_FACTORY_ABI, signer);
    setIsClaiming(true);
    try{
      if(account === tournamentOwnerAddress){
        const tx = await tournamentContract.withdraw(currentTournamnetID);
        const result = await tx.wait();
        if (result !== null) {
          console.log("Cliamed!");
          setIsClaiming(false);
          checkIfWinOrClaimable();
        }
      } else {
        const tx = await tournamentContract.claimWinnings(currentTournamnetID);
        const result = await tx.wait();
        if (result !== null) {
          console.log("Cliamed!");
          setIsClaiming(false);
          checkIfWinOrClaimable();
        }

      }
    } catch(error)  {
      console.log(error);
      setIsClaiming(false);
    }

  }

  const tableForBalance = () => {
    return(tournamentsResults.map((item, index) => {
      if(index<8){
        return(
          <tr key={index} >
            <td className='bg-[#004D87] rounded-[7px]'>{index+1}</td>
            <td className="px-6 py-2 text-sm  whitespace-nowrap bg-[#004D87] rounded-[7px]">
              {item.address.slice(0,6)}...{item.address.slice(-4)}
            </td>
            <td className="px-6 py-2 text-sm  whitespace-nowrap bg-[#004D87] rounded-[7px]">
              {item.balance}
            </td>
            <td className="px-6 py-2 text-sm  whitespace-nowrap bg-[#004D87] rounded-[7px]">
              {item.count}
            </td>
          </tr>
        )
      }
    }));
  }

  const checkIfDeposit = async() => {
    if(currentTournamnetID>0) {
      const tx = await tournamentService.getUserTournament(currentTournamnetID, account);

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const tournamentContract = new ethers.Contract(TOURNAMENT_FACTORY_ADDRESS, TOURNAMENT_FACTORY_ABI, signer);
      const createdTournaments = await tournamentContract.createdTournaments(currentTournamnetID);
      const startime = createdTournaments["tournamentStartTime"] - new Date().getTime()/1000;


      let depositedToContract = false;

      if(currentTournamnetID>0) {
        depositedToContract = await tournamentContract.depositedUserToTournament(currentTournamnetID,account);
      }

      if(tx.length==1 && tx[0]["balance"]>0){
        setIsDeposit(true);
      } else if(depositedToContract && startime > 0){
          const sign = await handleSign("Deposit");
          await tournamentService.depositMatics({
              "signature": sign.signature,
              "message": sign.message,
              "tournament":Number(currentTournamnetID)
          });
          setIsDeposit(true);
          getUserTournament(currentTournamnetID,account);
      } else{
        setIsDeposit(false);
      }
    }
  } 

  const checkIfWinOrClaimable = async() => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const tournamentContract = new ethers.Contract(TOURNAMENT_FACTORY_ADDRESS, TOURNAMENT_FACTORY_ABI, signer);
    if(currentTournamnetID>0) {
      const winner = await tournamentContract.tournamentWinners(currentTournamnetID,account);
      if(Number(winner.percentage) != 0){
        setIsPrize(true);
      } else {
        setIsPrize(false);
      }
    }
  }

  const checkIfTournamentRole = async() => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const tournamentContract = new ethers.Contract(TOURNAMENT_FACTORY_ADDRESS, TOURNAMENT_FACTORY_ABI, signer);
    const role = await tournamentContract.hasRole(tournamentRole,account);
    if(role){
      setTournamnetCreator(true);
    } else {
      setTournamnetCreator(false);
    }
  }

  const handleMessage = async() => {
    const sign = await SignMessage({
      setError,
      "message":"I want to play the coin flip game!"
    });
    sessionStorage.setItem("signature", JSON.stringify(sign));

    const data = {
      "address":account,
      "signature":sign.signature,
      "message":"I want to play the coin flip game!"
    }
    
    const tx = await tournamentService.login(data);
    if(tx){
      //
      setSign(true);
      return(true);
    } else {
      //
      return false;
    }
  }

  useEffect( () => {
    if(account) {
      const jwt_token = sessionStorage.getItem("token");
      const current_address =   sessionStorage.getItem("account");
      if(jwt_token && current_address === account) {
        setSign(true);
        return;
      }
      sessionStorage.setItem("account",account);
      handleMessage();
      //
    }
  },[account]);
  
  useEffect(() => {
    const initTournament = async() => {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();

      // tournament contract
      const tournamentContract = new ethers.Contract(TOURNAMENT_FACTORY_ADDRESS, TOURNAMENT_FACTORY_ABI, signer);

      // get the deposit
      setDepositAmount(Number(await tournamentContract.depositAmount())/Math.pow(10,18));
      
      // the last created tournament ID
      const _currentTournamnetID = Number(await tournamentContract.currentTournamnetID())-1;
      setCurrentTournamentID(_currentTournamnetID);


      // the last create tournament data
      const createdTournaments = await tournamentContract.createdTournaments(_currentTournamnetID);
      setCurrentCreatedTournament(createdTournaments);
      setTotalBalance(Number(createdTournaments["totalDepositAmount"])/Math.pow(10,18));
      setInit(true);
    }
    if(account){
      initTournament();
      
    }
  }, [account]);

  useEffect(()=>{
    if(init && account) {
      getLiveTournament();
      checkIfTournamentRole();
    }
  },[account, init])

  useEffect(()=>{
    if(account && currentTournamnetID>0) {
        checkIfDeposit();
        checkIfWinOrClaimable();
    }
  },[account,currentTournamnetID])

  useEffect(() => {

    if(enterTime<0 && isStated!==2 ){
      getLiveTournament();
    }
    const intervalId = setInterval(() => {
      setEnterTime(enterTime - 1);
    }, 1000);

    return () => clearInterval(intervalId);
        
  }, [enterTime]);

  useEffect(() => {
    sessionStorage.setItem("currentPage", "tournament");
  }, [])

  
  return (
      
    <div className='relative h-[100vh] bg-[url("../public/img/bg.jpg")] bg-bottom bg-cover bg-repeat-x overflow-x-hidden text-[#054462] font-sans font-bold'>
      <Navbar/>
      <div className=' py-[1vh] mx-auto mt-[5px]'>
        <div className="font-bold sm:font-[700] text-[48px] sm:text-[66px] text-center uppercase text-[#000000]">Tournament Mode</div>
      </div>

      {(isStated===0 || isStated===2) && creatorRole &&<div className="text-center">
        <label className='text-[#000000] font-[700] text-[20px]'>Start&nbsp;Time:&nbsp;</label><input type="number" min="0" step="0.1" onChange={(e)=>setStartTime(e.target.value)}/>&nbsp;Hour<p></p>
        <label className='text-[#000000] font-[700] text-[20px]'>End&nbsp;&nbsp;&nbsp;&nbsp;Time:&nbsp;</label><input type="number" min="0" step="0.1" onChange={(e)=>setEndTime(e.target.value)}/>&nbsp;Hour<p></p>
        <div className="text-[#000000] text-[16px]">{timeStatus}</div>
        {isCreating?<button className='px-[8px] sm:px-[10px] py-[3px] sm:py-[5px] my-[3vh] text-center bg-[#D6EFFF] text-[#000000] hover:scale-110   text-xl sm:text-2xl rounded-[10px]' disabled><i className="fa fa-spinner fa-spin"></i>
          Creating New Tournament
        </button>:<button className='px-[8px] sm:px-[10px] py-[3px] sm:py-[5px] my-[3vh] text-center bg-[#004D87] hover:scale-110  hover:bg-[#D6EFFF] text-[#ffffff] hover:text-[#000000] text-xl sm:text-2xl rounded-[10px]' onClick={() => CreateTournament()}> 
          Create New Tournament
        </button>}
        <br></br>
        {/* set deposit amount */}
        <div className="text-center">
          <label className='text-[#000000] font-[700] text-[20px]'>Deposit&nbsp;Amount:&nbsp;</label><input type="number" min="5" step="5" onChange={(e)=>setDepositAmount(e.target.value)}/>&nbsp;Matic<p></p>
        </div>
        {isSetting?<button className='px-[8px] sm:px-[10px] py-[3px] sm:py-[5px] my-[3vh] text-center bg-[#D6EFFF] text-[#000000] hover:scale-110   text-xl sm:text-2xl rounded-[10px]' disabled><i className="fa fa-spinner fa-spin"></i>
          Setting Amount...
        </button>:<button className='px-[8px] sm:px-[10px] py-[3px] sm:py-[5px] my-[3vh] text-center bg-[#004D87] hover:scale-110  hover:bg-[#D6EFFF] text-[#ffffff] hover:text-[#000000] text-xl sm:text-2xl rounded-[10px]' onClick={() => setDepositAmountForTournament()}> 
          Set Amount
        </button>}

        {/* add new tournament role address */}
        <div className="text-center">
          <label className='text-[#000000] font-[700] text-[20px]'>Tournamnet&nbsp;Role:&nbsp;</label><input type="text" onChange={(e)=>setTournamentAddress(e.target.value)}/><p></p>
        </div>
        {isTASetting?<button className='px-[8px] sm:px-[10px] py-[3px] sm:py-[5px] my-[3vh] text-center bg-[#D6EFFF] text-[#000000] hover:scale-110   text-xl sm:text-2xl rounded-[10px]' disabled><i className="fa fa-spinner fa-spin"></i>
          Setting new Role...
        </button>:<button className='px-[8px] sm:px-[10px] py-[3px] sm:py-[5px] my-[3vh] text-center bg-[#004D87] hover:scale-110  hover:bg-[#D6EFFF] text-[#ffffff] hover:text-[#000000] text-xl sm:text-2xl rounded-[10px]' onClick={() => setTournamnetAddressForRole()}> 
          Set NEW Role
        </button>}
        <p></p>


        {isWinning?<button className='px-[8px] sm:px-[10px] py-[3px] sm:py-[5px] my-[3vh] text-center bg-[#D6EFFF] text-[#000000]  hover:scale-110   text-xl sm:text-2xl rounded-[10px]' disabled><i className="fa fa-spinner fa-spin"></i>
          Setting Winners...
        </button>:<button className='px-[8px] sm:px-[10px] py-[3px] sm:py-[5px] my-[3vh] text-center bg-[#004D87] hover:scale-110  hover:bg-[#D6EFFF] text-[#ffffff] hover:text-[#000000] hover:scale-110   text-xl sm:text-2xl rounded-[10px]' onClick={() => SetTournamentWinner()}>
          Set Winners
        </button>}

        <button className='px-[8px] sm:px-[10px] py-[3px] sm:py-[5px] my-[3vh] text-center bg-[#004D87] hover:scale-110  hover:bg-[#D6EFFF] text-[#ffffff] hover:text-[#000000] hover:scale-110   text-xl sm:text-2xl rounded-[10px]' onClick={() => resetTournament()}>
          Reset Tournament
        </button>

        {isWinner===1&&<div className="text-[#000000] text-[16px] uppercase">Winners have just been set :)</div>}
        {isWinner===2&&<div className="text-[#000000] uppercase">Please set winners :(</div>}
      </div>
      }
      {isStated===0 && !creatorRole &&
      <div className='grid lg:grid-cols-3 gap-x-4 gap-y-12 mt-[10vh]'>
        <div className='flex flex-col lg:col-span-1 font-[700] text-[16px] sm:text-[24px] tracking-[.lem] mx-[50px] text-center font-sans uppercase gap-y-4'>
          <div className='w-full text-[#ffffff] bg-[#004D87] rounded-[7px] px-[5px] sm:px-[10px] lg:px-[15px] py-[3px] sm:py-[4px] lg:py-[6px]'>pool distribution</div>
          <div className='w-full text-[#ffffff] bg-[#7B40E3] rounded-[7px] px-[5px] sm:px-[10px] lg:px-[15px] py-[3px] sm:py-[4px] lg:py-[6px]'>winner: 50% of pool</div>
          <div className='w-full text-[#ffffff] bg-[#9764F0] rounded-[7px] px-[5px] sm:px-[10px] lg:px-[15px] py-[3px] sm:py-[4px] lg:py-[6px]'>second: 20% of pool</div>
          <div className='w-full text-[#000000] bg-[#B188F9] rounded-[7px] px-[5px] sm:px-[10px] lg:px-[15px] py-[3px] sm:py-[4px] lg:py-[6px]'>third: 10% of pool</div>
          <div className='w-full text-[#000000] bg-[#D1B9FC] rounded-[7px] px-[5px] sm:px-[10px] lg:px-[15px] py-[3px] sm:py-[4px] lg:py-[6px]'>fourth: 5% of pool</div>
          <div className='w-full text-[#000000] bg-[#E0D2F9] rounded-[7px] px-[5px] sm:px-[10px] lg:px-[15px] py-[3px] sm:py-[4px] lg:py-[6px]'>fifth: 5% of pool</div>
          <div className='w-full text-[#000000] bg-[#ffffff] rounded-[7px] px-[5px] sm:px-[10px] lg:px-[15px] py-[3px] sm:py-[4px] lg:py-[6px]'>treasury: 10% of pool</div>
        </div>
        <div className='mx-[50px] text-center flex flex-col lg:col-span-1  gap-y-8 text-[16px] sm:text-[24px]'>  
          <div className='font-[700]  bg-[#004D87] border-[#004D87]  border-2 rounded-[7px]  px-[5px] sm:px-[10px] lg:px-[15px] py-[3px] sm:py-[4px] lg:py-[6px] text-[#ffffff] uppercase w-full'>
            <div>Next Tournament Begins in...</div>
          </div>
          <div className='text-[#000000] font-[700] text-[64px]'>
            {enterTime>=0?enterTime:0}(S)
          </div>
          {sign&&<div className='bg-[#004D87] border-[#004D87]  border-2  rounded-[7px] px-[5px] sm:px-[10px] lg:px-[15px] py-[3px] sm:py-[4px] lg:py-[6px] uppercase text-[#ffffff] w-full' onClick = {(e)=> depositMatic(e)} disabled={isDeposited || isDepositing || currentTournamnetID===0}>
            {isDepositing?<div className="flex justify-center"><svg className="w-5 h-5 animate-spin my-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="#054462" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="#054462"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                  </path>
                </svg>Depositing Matic...</div>:<div>{isDeposited?`YOU HAVE ENTERED!`:`Deposit ${depositAmount} Matic to enter`}</div>}
          </div>}
          <div className="text-[#ff0000] text-[16px]">{depositErrorMsg}</div>
          <div className='bg-[#004D87] border-[#004D87]  border-2 rounded-[7px]  px-[5px] sm:px-[10px] lg:px-[15px] py-[3px] sm:py-[4px] lg:py-[6px] text-[#ffffff]  uppercase w-full'>
            <div>Current pool size: {totalBalance} MATIC </div>
          </div>
        </div>
        <div className='mx-auto lg:col-span-1'>
          <img src="img/tournament.png"/>
        </div>
      </div>
      }
      {isStated===1&&
        <div className='grid 2xl:grid-cols-6  gap-y-4 2xl:gap-x-4 mt-[50px]'>
          <div className='2xl:col-span-2 mx-[20px] text-center text-[#ffffff] font-[700] uppercase'>
            <div className='bg-[#004D87] my-[20px] text-[20px] sm:text-[24px] px-[5px] sm:px-[10px] lg:px-[15px] py-[3px] sm:py-[4px] lg:py-[6px] rounded-[7px]'>Total Pool: {totalBalance} MATIC</div>
            {myBalance<=0?<div className='bg-[#004D87] my-[20px] text-[20px] sm:text-[24px] px-[5px] sm:px-[10px] lg:px-[15px] py-[3px] sm:py-[4px] lg:py-[6px] rounded-[7px] text-[#ff5555]'>BALANCE: {myBalance} ICE</div>:<div className='bg-[#004D87] my-[20px] text-[20px] sm:text-[24px] px-[5px] sm:px-[10px] lg:px-[15px] py-[3px] sm:py-[4px] lg:py-[6px] rounded-[7px]'>BALANCE: {myBalance} ICE</div>}
            {count===0?<div className='bg-[#004D87] my-[20px] text-[20px] sm:text-[24px] px-[5px] sm:px-[10px] lg:px-[15px] py-[3px] sm:py-[4px] lg:py-[6px] rounded-[7px] text-[#ff5555]'>Remain Flip Counts: {count} Times</div>:<div className='bg-[#004D87] my-[20px] text-[20px] sm:text-[24px] px-[5px] sm:px-[10px] lg:px-[15px] py-[3px] sm:py-[4px] lg:py-[6px] rounded-[7px]'>Remain Flip Counts: {count} Times</div>}
            <div className='bg-[#004D87] my-[20px] text-[20px] sm:text-[24px] px-[5px] sm:px-[10px] lg:px-[15px] py-[3px] sm:py-[4px] lg:py-[6px] rounded-[7px]'>Total Players: {totalPlayers}</div>
            <div className='bg-[#004D87] my-[20px] text-[20px] sm:text-[24px] px-[5px] sm:px-[10px] lg:px-[15px] py-[3px] sm:py-[4px] lg:py-[6px] rounded-[7px]'>Players Remaining: {remainPlayers}</div>
            <div className='bg-[#004D87] my-[20px] text-[20px] sm:text-[24px] px-[5px] sm:px-[10px] lg:px-[15px] py-[3px] sm:py-[4px] lg:py-[6px] rounded-[7px]'>Time Remaining: {enterTime}S</div>
          </div>  
          <div  className='2xl:col-span-2'>
            <div className="flex justify-center text-xl space-x-8">
              <div className={`${guess === 1 ? "scale-110" : ""} flex flex-col hover:scale-110 text-[#000000]`}><button onClick={() => setGuess(1)}>{guess === 1 ?<img src="img/head-hover.svg" />:<img src="img/head.svg" />}HEAD</button></div>
              <div className={`${guess === 0 ? "scale-110" : ""} flex flex-col hover:scale-110 text-[#000000]`}><button onClick={() => setGuess(0)}>{guess === 0 ?<img src="img/tail-hover.svg" />:<img src="img/tail.svg" />}TAIL</button></div>
            </div>
            <div className="flex justify-center font-[700] pt-[1vh] gap-x-4">
              <div>
                <button className={`${betAmount === 1 ? "bg-[#004D87]  text-[#ffffff]  " : "bg-[#D6EFFF] hover:bg-[#004D87] text-[#000000] hover:text-[#ffffff]"} text-[17px] px-[20px] py-[10px] text-center   hover:scale-110   rounded-[7px]`} onClick={() => setBetAmount(1)} width={"140px"} height={"50px"}>
                  1 ICE
                </button>
              </div>
              <div>
                <button className={`${betAmount === 2 ? "bg-[#004D87]  text-[#ffffff]  " : "bg-[#D6EFFF] hover:bg-[#004D87] text-[#000000] hover:text-[#ffffff]"} text-[17px] px-[20px] py-[10px] text-center   hover:scale-110   rounded-[7px]`} onClick={() => setBetAmount(2)} width={"140px"} height={"50px"}>
                  2 ICE
                </button>
              </div>
              <div>
                <button className={`${betAmount === 5 ? "bg-[#004D87]  text-[#ffffff]  " : "bg-[#D6EFFF] hover:bg-[#004D87] text-[#000000] hover:text-[#ffffff]"} text-[17px] px-[20px] py-[10px] text-center   hover:scale-110   rounded-[7px]`} onClick={() => setBetAmount(5)} width={"140px"} height={"50px"}>
                  5 ICE
                </button>
              </div>
            </div>
            <div className=' flex justify-center font-[700] pt-[1vh] gap-x-4'>
              <div>
                <button className={`${betAmount === 10 ? "bg-[#004D87]  text-[#ffffff]  " : "bg-[#D6EFFF] hover:bg-[#004D87] text-[#000000] hover:text-[#ffffff]"} text-[17px] px-[20px] py-[10px] text-center   hover:scale-110   rounded-[7px]`} onClick={() => setBetAmount(10)} width={"140px"} height={"50px"}>
                  10 ICE
                </button>
              </div>
              <div>
                <button className={`${betAmount === 25 ? "bg-[#004D87]  text-[#ffffff]  " : "bg-[#D6EFFF] hover:bg-[#004D87] text-[#000000] hover:text-[#ffffff]"} text-[17px] px-[20px] py-[10px] text-center   hover:scale-110   rounded-[7px]`} onClick={() => setBetAmount(25)} width={"140px"} height={"50px"}>
                  25 ICE
                </button>
              </div>
            </div>
            {sign && isLoading &&<div className="flex items-center justify-center">
              <div className="flex flex-row justify-center w-[322px] h-[50px] px-[8px] sm:px-[10px] py-[3px] sm:py-[5px] my-[3vh] text-center bg-[#D6EFFF] text-[#000000]  text-[24px]  rounded-[5px] uppercase font-[700]">
                <img src="img/flipping.gif" className="rounded-[50%]"/>
                <p className="my-auto">Flipping...</p>
              </div>
            </div>}
            {sign  &&<div className='flex justify-center'>
              <button className=' w-[322px] h-[50px] px-[8px] sm:px-[10px] py-[3px] sm:py-[5px] my-[3vh] text-center bg-[#004D87] hover:bg-[#D6EFFF] hover:scale-110  text-[#ffffff] hover:text-[#000000] text-[24px] rounded-[5px] uppercase font-[700]' onClick={() => takeCoinFlip()}>
                Click here to flip
              </button>
            </div>}
            <div className="text-[#ff0055] font-bold text-[20px] text-center uppercase">{flipError}</div>
            {choice===1&&<div className="text-[#ff0055] font-bold text-[20px] text-center uppercase">Rugged</div>}
            {choice===2&&<div className="text-[#135F03] font-bold text-[20px] text-center uppercase">Good Choice</div>}
          </div>  
          <div  className='mx-auto 2xl:col-span-2'>
            <table className="table-auto w-full shadow-md border-separate border-spacing-2 sm:border-spacing-4  text-[#ffffff] text-center">
              <thead className=" ">
                <tr>
                  <th
                      scope="col"
                      className="px-[3px] sm:px-6 py-3 text-xs font-bold   uppercase bg-[#004D87] rounded-[7px]"
                  >
                    Rank
                  </th>
                  <th
                      scope="col"
                      className="px-[3px] sm:px-6 py-3 text-xs font-bold   uppercase bg-[#004D87] rounded-[7px]"
                  >
                    Address
                  </th>
                  <th
                      scope="col"
                      className="px-[3px] sm:px-6 py-3 text-xs font-bold   uppercase bg-[#004D87] rounded-[7px]"
                  >
                    Balance
                  </th>
                  <th
                      scope="col"
                      className="px-[3px] sm:px-6 py-3 text-xs font-bold   uppercase bg-[#004D87] rounded-[7px]"
                  >
                    Count
                  </th>
                </tr>
              </thead>
              <tbody className="">
                {tableForBalance()}
              </tbody>
            </table>
            <div className='text-center text-[#000000] font-bold text-[14px] mt-[23px] mb-[16px]'>YOU MUST FLIP 10 TIMES TO BE ELIGIBLE TO WIN!</div>
            <div className="bg-[#004D87]  hover:bg-[#D6EFFF] text-[#ffffff] hover:text-[#000000] text-center px-[10px] py-[5px] m-2 rounded-[10px] tracking-[.21m] leading-6" onClick={()=>getTournamentBalance(currentTournamnetID)}>Refresh</div>
          </div>
      </div>
      }
     
       {isStated===2&&
        <div>
          {countdownTime - Date.now() < 0&& account!=tournamentOwnerAddress &&<div className="text-center font-bold font-[700] text-[#000000] text-[24px]">{status}</div>}
          {!claimed&&countdownTime - Date.now() > 0 &&<div className="text-center font-bold font-[700] text-[#000000] text-[24px]">Please wait until winners will be confirmed</div>}
          {claimed&&countdownTime - Date.now() > 0 && <div className="text-center font-bold font-[700] text-[#000000] text-[24px]">Please claim rewards within :  <Countdown date={countdownTime} /></div>}
          <table className="table-auto w-full shadow-md   rounded border-separate border-spacing-2 sm:border-spacing-4  text-[#ffffff] text-center">
            <thead className="">
              <tr>
                <th
                    scope="col"
                    className="px-[3px] sm:px-6 py-3 text-xs font-bold  bg-[#004D87] rounded-[7px] uppercase "
                >
                  Rank
                </th>
                <th
                    scope="col"
                    className="px-[3px] sm:px-6 py-3 text-xs font-bold  bg-[#004D87] rounded-[7px] uppercase "
                >
                  Address
                </th>
                <th
                    scope="col"
                    className="px-[3px] sm:px-6 py-3 text-xs font-bold  bg-[#004D87] rounded-[7px] uppercase "
                >
                  Pay Out
                </th>
              </tr>
            </thead>
            <tbody className="">
              {claim()}
            </tbody>
          </table>
        <div className="text-center  mr-[20px] mt-[20px]">
        {status===""&& isPrize && account!==tournamentOwnerAddress && <button className={`${!claimed || countdownTime - Date.now() <0 ? "hidden":""} bg-[#004D87] hover:bg-[#D6EFFF]  text-[#ffffff] hover:text-[#000000] text-[24px] px-[40px] py-[10px] rounded-[7px] uppercase`} onClick={()=>claimRewards()}   disabled={!claimed || countdownTime - Date.now() <0}>{!isClaiming?"Claim your prizes!":"Claiming your prizes..."}</button>}

        {status===""  && account===tournamentOwnerAddress&&<button className={`${!claimed || countdownTime - Date.now() > 0?"hidden":""} bg-[#004D87] hover:bg-[#D6EFFF]  text-[#ffffff] hover:text-[#000000] text-[24px] px-[40px] py-[10px] rounded-[7px] uppercase`} onClick={()=>claimRewards()}   disabled={!claimed || countdownTime - Date.now() > 0}>{!isClaiming?"Withdraw!":"Withdrawing..."}</button>}
        </div></div>
      } 

      <div className='text-center  font-bold'>
        <div className="mt-10 mb-20"><Link to="/faq" className=" text-sm sm:text-base  no-underline text-[#000000]">FAQ</Link> | <Link to="/howto" className=" text-sm sm:text-base  no-underline text-[#000000]">How To Play</Link> | <Link to="/responsibility" className=" text-sm sm:text-base  no-underline text-[#000000]">Flip Responsibly</Link></div>
      </div>
      <div className="font-bold text-center text-[#000000]">
        <div>Audited By</div>
        <div className="flex justify-center mb-[50px]" width={"250px"} height={"50px"}><img src="img/audit.png"/></div>
      </div>
    </div>
  );
}

export default Tournament;
