/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import "./App.css";
import useMetaMask from "../hooks/useMetaMask";
import Coinflip from "../components/coinflip/coinflip";
import Navbar from "../components/Navbar/navbar";
// import { Link } from "react-router-dom";
import "@fontsource/silkscreen";
// import APIS to interact with Node back-end

const Home = () => {
  const {
    connect,
    isActive,
    shouldDisable,
    account
  } = useMetaMask();

  useEffect(() => {
    sessionStorage.setItem("currentPage", "icedout");
  }, [])

  return (
    <div className='relative h-[100vh] bg-[url("../public/img/bg.jpg")] bg-bottom bg-cover bg-repeat-x overflow-x-hidden font-sans tracking-[.1em]'>
      <Navbar/>
      
      <div className='flex flex-col'>
        <div className=' py-[1vh] mx-auto mt-[5px]'>
          <div className="font-[700] text-[66px] text-center uppercase ">Iced Out Coin Flip</div>
        </div>
        <div className="text-center">
          {
            !isActive ?
              <button className=' mt-[10px] px-[20px] py-[10px] font-bold text-white outline-[2px] outline-dashed outline-cyan-400 bg-cyan-400 hover:bg-cyan-400 hover:text-cyan-500 focus:text-cyan-500 hover:scale-110 hover:outline-offset-4 duration-1000 hover:animate-pulse' onClick={connect} disabled={shouldDisable}>
                Connect to MetaMask
              </button>
              :
              <Coinflip />
          }
        </div>
      </div>
    </div>
  );
}

export default Home;
